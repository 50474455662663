@media (max-width: 700px) {
  #hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #hero p {
    text-align: center;
  }

  #itemBodyy {
    padding: 50px 10px;
  }

  #itemBody {
    padding: 10px;
    width: 90%;
  }

  #h1 {
   font-size: 20px;
  }

  #aboutImg {
    display: flex;
    justify-content: center;
    margin: 0 0 20px 0;
  }

  #aboutText {
    padding: 20px;
    text-align: center;
  }

  #svg {
   margin: 20px 0 0 50px;
  }

  #productText {
    text-align: center;
  }

  #productDownload {
    display: flex;
    justify-content: center;
  }

  #contactText {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  #contactTextt {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
  }

  /* FAQS */
  #faqsContainer {
    margin: 60px 0;
  }

  #form {
    padding: 5px 10px;
    width: 350px;
    height: 50px;
  }

  #form > input {
    width: 300px;
    height: 50%;
  }

  #title {
    font-size: 7px;
  }

  #contactFind {
    text-align: center;
  }
}