* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #ffff;
  border: #C3996C 2px solid;
  cursor: pointer;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-thumb {
  background-color: #3A1F19;
  border-radius: 100px;
  height: 10px;
}
